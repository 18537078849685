import styled from 'styled-components';
import React, { FC, Fragment, ReactNode } from 'react';
import { gql } from '@apollo/client';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Stack from '@oberoninternal/travelbase-website/dist/components/Stack';
import withAuth, { WithAuthProps } from '@oberoninternal/travelbase-website/dist/hoc/withAuth';
import { applicable, not } from '@oberoninternal/travelbase-website/dist/utils/fp';
import { isDeclined, isUpcoming } from '@oberoninternal/travelbase-website/dist/utils/order';
import { Item } from '@oberoninternal/travelbase-website/dist/components/Order';
import Divider from '@oberoninternal/travelbase-website/dist/components/Divider';
import { UpcomingReservationsDocument, useUpcomingReservationsQuery } from '../generated/graphql';
import { OrderItemFragment } from '@oberoninternal/travelbase-website/dist/generated/graphql';
import Skeleton from 'react-loading-skeleton';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';

export const query = gql`
    query UpcomingReservations {
        viewer {
            id
            orders {
                ...MyOrder
            }
        }
    }

    fragment MyOrder on Order {
        id
        number
        createdDate
        paymentOptions {
            fullPaymentDueDate
            totalAmountDue
        }
        orderItems {
            ...OrderItem
        }
    }

    fragment OrderItem on OrderItem {
        ... on Booking {
            downloadVoucherUrl

            status
            requiresApproval
            amountAdults
            amountPets
            amountChildren
            amountBabies
            amountYouths
            arrivalDate
            departureDate
            handleDepositPayment
            deposit
            paymentPrice

            rentalUnit {
                id
                code
                name
                slug
                accommodation {
                    id
                    hasPublicPage
                    name
                    place
                    checkInEndTime
                    checkInStartTime
                    checkOutTime
                    type
                }
                type
                maxOccupancy
                amountBedrooms
                jpegThumbnail: listImage {
                    transform(config: TILE, format: JPEG) {
                        ...Transform
                    }
                }
                webpThumbnail: listImage {
                    transform(config: TILE, format: WEBP) {
                        ...Transform
                    }
                }
            }
        }
    }

    fragment Transform on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;

interface Props extends WithAuthProps {
    title: ReactNode;
}

const UpcomingReservations: FC<React.PropsWithChildren<Props>> = ({ loading, isAuthorized, title }) => {
    // get from cache
    const { data } = useUpcomingReservationsQuery({
        errorPolicy: 'ignore',
        skip: !isAuthorized,
    });

    const upcomingOrders =
        data?.viewer?.orders?.filter(order => order.orderItems.some(applicable(isUpcoming, not(isDeclined)))) ?? [];

    if (!loading && upcomingOrders.length === 0) {
        // hide entire section if no upcoming orders
        return null;
    }

    return (
        <>
            <SectionHeading>
                <Title elementType="h3">{title}</Title>
            </SectionHeading>
            <StyledWrapper>
                <Stack spacing={[null, null, 5]}>
                    {loading ? (
                        <StyledSkeleton />
                    ) : (
                        upcomingOrders.map((order, i) => (
                            <Fragment key={order.id ?? i}>
                                {order.orderItems.map(item => (
                                    <Item
                                        key={i}
                                        item={item as OrderItemFragment}
                                        orderId={order.id}
                                        linkProps={{ href: `/my/order/${order.id}` }}
                                    />
                                ))}
                                <Divider className={'lt-s'} my={[4, null, 0]} />
                            </Fragment>
                        ))
                    )}
                </Stack>
            </StyledWrapper>
        </>
    );
};

export default withAuth(UpcomingReservations, {
    authQuery: UpcomingReservationsDocument,
    withLoadingProp: true,
});

const StyledSkeleton = styled(Skeleton)`
    height: 399px;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        height: 156px;
    }
`;

const SectionHeading = styled(ContentWrapper as FC<React.PropsWithChildren<unknown>>)`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    }
    height: 7.2rem;
    display: flex;
    align-items: center;
    background: white;
`;

const StyledWrapper = styled(ContentWrapper as FC<React.PropsWithChildren<unknown>>)`
    padding-bottom: 4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-bottom: ${({ theme }) => theme.spacing['80_XXLarge']};
    }
`;
