import styled from 'styled-components';
import React, { FC, useEffect, useState } from 'react';
import { Box } from '@rebass/grid';
import PlayIcon from '../icons/PlayIcon';
import dynamic from 'next/dynamic';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';

interface Props {
    videoUrl: string;
    videoTitle: string | null;
    videoDescription: string | null;
}

const ReactPlayer = dynamic(() => import('react-player'));

const VideoCard: FC<React.PropsWithChildren<Props>> = ({ videoUrl, videoTitle, videoDescription }) => {
    const [previewing, setPreviewing] = useState(true);
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

    useEffect(() => {
        fetch(`https://noembed.com/embed?url=${videoUrl}`)
            .then(res => res.json())
            .then((resp: { thumbnail_url: string }) => setThumbnailUrl(resp.thumbnail_url));
    }, [videoUrl]);

    return (
        <Item>
            <PlayerContainer>
                {/* eslint-disable-next-line no-nested-ternary */}
                {previewing ? (
                    thumbnailUrl ? (
                        <Preview
                            style={{ backgroundImage: `url(${thumbnailUrl})` }}
                            onClick={() => setPreviewing(false)}
                        >
                            <PlayIcon />
                        </Preview>
                    ) : null
                ) : (
                    <ReactPlayer url={videoUrl} playing controls />
                )}
            </PlayerContainer>
            <Box pt={4}>
                <Title variant={'small'} elementType={'h3'}>
                    {videoTitle}
                </Title>
                {videoDescription && <LineLimitBody variant={'small'}>{videoDescription}</LineLimitBody>}
            </Box>
        </Item>
    );
};

export default VideoCard;

const Item = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing['70_XLarge']};
    min-width: 0;

    h3 {
        margin-bottom: ${({ theme }) => theme.spacing['20_Tiny']};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex: 3;
        display: flex;
        flex-direction: column;
        min-width: 0;
        margin-bottom: ${({ theme }) => theme.spacing['80_XXLarge']};
    }
`;

const Preview = styled.div`
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PlayerContainer = styled.div`
    max-width: 92.8rem;
    position: relative;
    width: 100% !important;
    height: auto !important;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 75%;
    }

    > * {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: auto !important;
    }
`;

const LineLimitBody = styled(Body)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
