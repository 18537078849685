"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = exports.Item = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _checkout = require("../context/checkout");
var _fp = require("../utils/fp");
var _order = require("../utils/order");
var _OrderItem = require("./designsystem/OrderItem");
var _OrderBooking = _interopRequireDefault(require("./OrderBooking"));
var _OrderTicket = _interopRequireDefault(require("./OrderTicket"));
var _Stack = _interopRequireDefault(require("./Stack"));
const _excluded = ["query"],
  _excluded2 = ["item", "orderId"];
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment MyOrder on Order {
        id
        number
        createdDate
        paymentOptions {
            fullPaymentDueDate
            totalAmountDue
        }
        orderItems {
            ...OrderItem
        }
        payments {
            status
        }
    }

    fragment OrderItem on OrderItem {
        id
        ...OrderBooking
        ...OrderTicket
        ...OrderSummaryItem
    }
`;
const Order = ({
  data
}) => {
  const {
    orderItems,
    paymentOptions,
    createdDate,
    id
  } = data;
  const _useRouter = (0, _router.useRouter)(),
    {
      query
    } = _useRouter,
    router = (0, _objectWithoutProperties2.default)(_useRouter, _excluded);
  const {
    init
  } = (0, _checkout.useCheckoutDispatch)();
  const intl = (0, _reactIntl.useIntl)();
  return __jsx(_grid.Box, null, __jsx(_grid.Flex, {
    justifyContent: "space-between",
    pb: 4,
    alignItems: "center"
  }, __jsx(_grid.Box, null, __jsx(StyledTitle, {
    variant: "small"
  }, (0, _order.getOrderTitle)(data, router.locale)), __jsx(_Body.default, null, createdDate && __jsx(_reactIntl.FormattedMessage, {
    id: "zMkMEN",
    defaultMessage: [{
      "type": 0,
      "value": "Gemaakt op "
    }, {
      "type": 1,
      "value": "date"
    }],
    values: {
      date: __jsx(_reactIntl.FormattedDate, {
        format: "DAYMONTHYEAR",
        value: new Date(createdDate)
      })
    }
  }))), orderItems.every((0, _fp.not)(_order.isDeclined)) && paymentOptions && paymentOptions.totalAmountDue > 0 && __jsx(_react.default.Fragment, null, __jsx(_Stack.default, {
    className: "gt-s",
    spacing: 4,
    variant: "inline",
    alignItems: "center",
    mr: [null, null, null, 7]
  }, __jsx(_Body.default, {
    variant: "tiny",
    style: {
      fontWeight: 'normal'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "5OYac4",
    defaultMessage: [{
      "type": 0,
      "value": "Nog te voldoen "
    }, {
      "type": 1,
      "value": "price"
    }],
    values: {
      price: __jsx("strong", {
        style: {
          fontWeight: 700
        }
      }, __jsx(_reactIntl.FormattedNumber, {
        value: paymentOptions.totalAmountDue,
        format: "EUR"
      }))
    }
  })), __jsx(_Button.default, {
    onClick: () => {
      init('order');
      router.push(`/my/order/${id}/checkout/payment`);
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "U33juO",
    defaultMessage: [{
      "type": 0,
      "value": "Restant betalen"
    }]
  }))))), id && __jsx(_OrderItem.OrderItems, null, orderItems.map((item, i) => __jsx(Item, {
    key: i,
    item: item,
    orderId: id,
    linkProps: {
      href: `/my/order/${id}`,
      locale: intl.locale
    }
  }))));
};
var _default = exports.default = Order;
const Item = _ref => {
  let {
      item,
      orderId
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded2);
  if (!item.__typename) {
    return null;
  }
  switch (item.__typename) {
    case 'Booking':
      return __jsx(_OrderBooking.default, (0, _extends2.default)({
        data: item,
        orderId: orderId
      }, props));
    case 'Ticket':
      return __jsx(_OrderTicket.default, (0, _extends2.default)({
        data: item
      }, props));
    // TODO: implement UpsellPurchase
    case 'UpsellPurchase':
      return null;
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(item.__typename);
  }
};
exports.Item = Item;
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "Order__StyledTitle",
  componentId: "sc-36syaf-0"
})(["a{font:inherit;font-weight:400;}"]);